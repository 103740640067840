import useSWR from 'swr';

import { type DtoSharedAsset } from '@lp-lib/api-service-client/public';

import { GamePackUtils } from '../../../../src/components/Game/GamePack/utils';
import { apiService } from '../../../../src/services/api-service';
import {
  type GamePack,
  type GamePackShowcaseCard,
} from '../../../../src/types/game';

export function useGamePackShowcaseCards(pack: GamePack) {
  return useSWR([`game-packs`, pack.id, 'showcase-cards'], async () => {
    if (pack.marketingSettings?.useShowcaseCards) {
      const cardIds = pack.marketingSettings?.showcaseCardIds ?? [];
      if (cardIds.length === 0) return [];
      const sharedAssets = await apiService.media
        .querySharedAsset({
          type: 'by-ids',
          ids: cardIds.join(','),
          size: 100,
        })
        .next();
      const map = new Map<string, DtoSharedAsset>();
      for (const asset of sharedAssets) {
        map.set(asset.id, asset);
      }
      const cards: GamePackShowcaseCard[] = [];
      for (const id of cardIds) {
        const asset = map.get(id);
        if (!asset) continue;
        cards.push(GamePackUtils.ToShowcaseCard(asset));
      }
      console.log('cards', cards);
      return cards;
    }

    const brands = (await apiService.gamePack.getLinkedBrands(pack.id)).data
      .brands;
    return brands?.map<GamePackShowcaseCard>((brand) => ({
      id: brand.id,
      primaryText: brand.name,
      secondaryText: brand.showcaseText,
      media: brand.showcaseMedia,
    }));
  });
}
