import { ArrowLeftIcon, ArrowRightIcon } from '../icons/Arrows';

type ArrowProps = {
  className?: string;
  styles?: Partial<{
    arrow: string;
    disabled: string;
  }>;
  onClick?: () => void;
};

export function makeSlickArrowButtons(style: {
  base: string;
  prev: {
    container?: string;
    disabled?: string;
    arrow: string;
  };
  next: {
    container?: string;
    disabled?: string;
    arrow: string;
  };
}) {
  const prev = (props: ArrowProps): JSX.Element => {
    const disabled = props.className?.includes('slick-disabled');
    return (
      <div
        className={`${style.base} ${style.prev.container ?? ''} ${
          disabled && (props.styles?.disabled ?? style?.prev?.disabled)
        } ${props.styles?.arrow ?? ''}`}
        onClick={props.onClick}
      >
        <ArrowLeftIcon className={style.prev.arrow} />
      </div>
    );
  };
  const next = (props: ArrowProps): JSX.Element => {
    const disabled = props.className?.includes('slick-disabled');
    return (
      <div
        className={`${style.base} ${style.next.container ?? ''} ${
          disabled && (props.styles?.disabled ?? style?.next?.disabled)
        } ${props.styles?.arrow ?? ''}`}
        onClick={props.onClick}
      >
        <ArrowRightIcon className={style.next.arrow} />
      </div>
    );
  };
  return [prev, next];
}
