export function GameModeIcon(props: { className?: string }): JSX.Element {
  return (
    <svg
      className={props.className ?? 'w-6.5 h-6.5'}
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M12.2011 2.83562H11.6792C10.7416 2.83562 9.81046 3.14356 9.05697 3.70239C9.00265 3.74268 8.93581 3.76496 8.86744 3.76496H7.5472V1.54691C7.5472 1.24507 7.30213 1 7.00029 1C6.69846 1 6.45339 1.24507 6.45339 1.54691V3.76496H5.13338C5.06471 3.76496 4.99909 3.74298 4.94355 3.70239C4.19003 3.14358 3.25915 2.83562 2.3219 2.83562H1.79941C0.807236 2.83562 0 3.64895 0 4.64878V11.2754C0 12.2753 0.807236 13.0886 1.79941 13.0886H2.74212C3.48679 13.0886 4.14299 12.6403 4.41426 11.9456L4.91386 10.6663C5.10033 10.1886 5.55142 9.88012 6.06229 9.88012H7.93741C8.44831 9.88012 8.89911 10.1887 9.08584 10.6663L9.58544 11.945C9.85614 12.6399 10.5129 13.0883 11.2576 13.0883H12.2C13.1928 13.0883 13.9994 12.2753 13.9994 11.2751L14 4.64846C14.0006 3.64895 13.1935 2.83562 12.2011 2.83562ZM12.907 11.2752C12.907 11.6717 12.5902 11.9945 12.2014 11.9945H11.259C10.9681 11.9945 10.7117 11.8191 10.6052 11.5474L10.1056 10.2681C9.75374 9.36777 8.90313 8.78601 7.93846 8.78601H6.06335C5.09805 8.78601 4.24776 9.36741 3.89587 10.2681L3.39657 11.5474C3.29036 11.8191 3.034 11.9945 2.74284 11.9945H1.80013C1.4107 11.9945 1.09452 11.672 1.09452 11.2752L1.09422 4.64823C1.09422 4.25148 1.41101 3.92889 1.79982 3.92889H2.32232C3.0264 3.92889 3.72592 4.16022 4.29267 4.58048C4.53805 4.76207 4.8286 4.8582 5.13351 4.8582H8.86788C9.16881 4.8582 9.46729 4.75932 9.70934 4.58048C10.2755 4.16053 10.9756 3.92889 11.6797 3.92889H12.2016C12.591 3.92889 12.9072 4.25148 12.9072 4.64823L12.907 11.2752Z' />
      <path d='M11.7432 6.84447C11.7432 7.47745 11.2265 7.99079 10.5892 7.99079C9.95195 7.99079 9.43556 7.47746 9.43556 6.84447C9.43556 6.21119 9.95195 5.69815 10.5892 5.69815C11.2265 5.69815 11.7432 6.21118 11.7432 6.84447Z' />
      <path d='M4.43103 6.29756H4.05258V5.92522C4.05258 5.62338 3.80751 5.37831 3.50567 5.37831C3.20384 5.37831 2.95877 5.62338 2.95877 5.92522V6.29756H2.58094C2.2791 6.29756 2.03403 6.54263 2.03403 6.84447C2.03403 7.14631 2.2791 7.39138 2.58094 7.39138H2.95907L2.95876 7.76371C2.95876 8.06555 3.20383 8.31062 3.50567 8.31062C3.80751 8.31062 4.05258 8.06555 4.05258 7.76371V7.39138H4.43103C4.73287 7.39138 4.97794 7.14631 4.97794 6.84447C4.97794 6.54263 4.73348 6.29756 4.43103 6.29756Z' />
    </svg>
  );
}
