import pluralize from 'pluralize';

import { type PlayerRange } from '../../types/game';

export class PlayerRangeUtils {
  static Format(
    range: PlayerRange,
    label = 'Participant',
    fallbackMax: number | null = 125
  ): string {
    const numFormatter = new Intl.NumberFormat('en-US');
    const { min } = range;
    const max = range.max ?? fallbackMax;
    if (max === null) {
      return `${min}+ ${pluralize(label, min)}`;
    } else if (max === min) {
      return `${min} ${pluralize(label, min)}`;
    } else {
      return `${min}–${numFormatter.format(max)} ${pluralize(label, max)}`;
    }
  }

  /**
   * Determines if the given number of players is within the bounds of the given player range.
   * Returns 0 when numPlayers is within the given range.
   * Returns < 0 when numPlayers is below the given range.
   * Returns > 0 when numPlayers exceeds the given range.
   *
   * @param range
   * @param numPlayers
   */
  static InRange(range: PlayerRange, numPlayers: number): number {
    const min = range.min;
    const max = range.max === undefined ? Infinity : range.max;
    if (numPlayers < min) return -1;
    else if (numPlayers > max) return 1;
    else return 0;
  }
}
