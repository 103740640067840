import { type RandomizerBlockTierIndex } from '@lp-lib/game';

import { type TierData } from './types';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';

// Note(falcon): there's a relationship between the SettingTierData
// configuration and RandomizerBlockTierIndexValues. If we need to add/remove
// tiers, it's required to do so in both places. the type here should ensure
// there's defined setting for every possible index value in
// RandomizerBlockTierIndexValues. For completeness, use Infinity for the last
// setting index.
const randomizerSettings: {
  [k in RandomizerBlockTierIndex]: TierData;
} = {
  1: {
    start: 1,
    end: 3,
  },
  2: {
    start: 4,
    end: 6,
  },
  3: {
    start: 7,
    end: 11,
  },
  4: {
    start: 12,
    end: 19,
  },
  5: {
    start: 20,
    end: Infinity,
  },
} as const;

export const SettingTierData: TierData[] = Object.keys(randomizerSettings)
  .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
  .map((key) => uncheckedIndexAccess_UNSAFE(randomizerSettings)[key]);
