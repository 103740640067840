import { Link } from '@remix-run/react';
import pluralize from 'pluralize';
import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import { type Settings as SliderSettings } from 'react-slick';
import { useLatest } from 'react-use';
import { Waypoint } from 'react-waypoint';
import { useHydrated } from 'remix-utils/use-hydrated';

import {
  type DtoGamePack,
  EnumsGamePackDifficulty,
  EnumsGamePackVersion,
} from '@lp-lib/api-service-client/public';

import { makeSlickArrowButtons } from '../../../src/components/common/Slick';
import {
  type Classes,
  StagedTailwindTransition,
  type TailwindTransitionStage,
} from '../../../src/components/common/TailwindTransition';
import { GamePackCoverPres } from '../../../src/components/Game/GamePackCoverPres';
import { PlayerRangeUtils } from '../../../src/components/Game/PlayerRangeUtils';
import { DoubleRightArrow } from '../../../src/components/icons/Arrows';
import { getFeatureQueryParamNumber } from '../../../src/hooks/useFeatureQueryParam';
import { useInstance } from '../../../src/hooks/useInstance';
import { type Tag } from '../../../src/types';
import { type GamePack } from '../../../src/types/game';
import { fromDTOGamePack } from '../../../src/utils/api-dto';
import { ErrorMessage } from '../ErrorMessage';
import { useAnonFeatureChecker } from '../hooks/useAnonFeatureChecker';
import { useSSRLayoutEffect } from '../hooks/useSSRLayoutEffect';
import { ContentLoader } from '../SSRCompatible/ContentLoader';
import { Slider } from '../SSRCompatible/Slider';
import {
  GamePackHoverBrandsPreviewMulti,
  GamePackHoverBrandsPreviewSingle,
} from './GamePackHover';
import {
  ComingSoonGamePackBadge,
  FreeGamePackBadge,
  GamePackPlayable,
  NewGamePackBadge,
  ThumbsUpBadge,
} from './GamePackShared';
import { useGamePackShowcaseCards } from './hooks/useGamePackShowcaseCards';
import { makeAnonPackUrl } from './utils';

export const FEATURED_GAME_PACKS_SHOW_CAP = 8;

const settings = {
  num: 11,
  responsive: [
    {
      breakpoint: 3940,
      num: 10,
    },
    {
      breakpoint: 3440,
      num: 9,
    },
    {
      breakpoint: 3008,
      num: 8,
    },
    {
      breakpoint: 2560,
      num: 7,
    },
    {
      breakpoint: 1920,
      num: 6,
    },
    {
      breakpoint: 1680,
      num: 5,
    },
    {
      breakpoint: 1280,
      num: 4,
    },
    {
      breakpoint: 1024,
      num: 3,
    },
  ],
};

const [ArrowPrev, ArrowNext] = makeSlickArrowButtons({
  base: 'rounded-full border border-secondary bg-lp-black-002 w-12 h-12 md:w-15 md:h-15 absolute top-1/2 transform -translate-y-1/2 z-5 flex items-center justify-center cursor-pointer',
  prev: {
    container: '-left-6',
    disabled: 'opacity-25',
    arrow: 'w-7 h-7 fill-current',
  },
  next: {
    container: '-right-6',
    disabled: 'opacity-25',
    arrow: 'w-7 h-7 fill-current',
  },
});

export function useGamePackSliderSettings(): SliderSettings {
  const sliderSettings: SliderSettings = useMemo(() => {
    const s: SliderSettings = {
      infinite: false,
      slidesToShow: settings.num,
      slidesToScroll: settings.num,
      responsive: [],
      nextArrow: <ArrowNext />,
      prevArrow: <ArrowPrev />,
    };
    for (const e of settings.responsive) {
      s.responsive?.push({
        breakpoint: e.breakpoint,
        settings: {
          infinite: false,
          slidesToShow: e.num,
          slidesToScroll: e.num,
        },
      });
    }
    return s;
  }, []);
  return sliderSettings;
}

type GamePackCardStyles = Partial<{
  bg: string;
  border: string;
  size: string;
  cursor: string;
  badgesTranslateClassName: string;
}>;

export function LoadingGamePackCard(props: {
  styles?: GamePackCardStyles;
  backgroundColor?: string;
}): JSX.Element {
  return (
    <ContentLoader
      viewBox='0 0 248 250'
      backgroundColor={props.backgroundColor ?? '#101012'}
      foregroundColor='#161616'
      className={`
        ${props.styles?.size ?? 'w-62'}
      `}
    >
      <rect width='248' height='250' rx='20' />
    </ContentLoader>
  );
}

export function useGamePackLoader(
  n: number,
  backgroundColor?: string
): JSX.Element[] {
  return useMemo(
    () =>
      [...Array(n)].map((_, i) => (
        <LoadingGamePackCard
          key={i}
          backgroundColor={backgroundColor}
          styles={{ size: 'w-full mx-2' }}
        />
      )),
    [n, backgroundColor]
  );
}

function GamePackHoverBrandsPreview(props: { pack: GamePack }) {
  const stages = useInstance<TailwindTransitionStage<Classes>[]>(() => {
    return [{ classes: 'opacity-0' }, { classes: 'opacity-100' }];
  });
  const duration = getFeatureQueryParamNumber('game-pack-hover-delayed-ms');

  const { data: showcaseCards } = useGamePackShowcaseCards(props.pack);
  if (!showcaseCards || showcaseCards.length === 0) return null;

  return (
    <StagedTailwindTransition stages={stages}>
      {(ref, initial) => (
        <div
          ref={ref}
          className={`absolute left-0 top-0 w-full h-full transform ${initial}`}
          style={{
            transitionDuration: `${duration}ms`,
          }}
        >
          {showcaseCards.length === 1 ? (
            <GamePackHoverBrandsPreviewSingle
              play
              card={showcaseCards[0]}
              mediaClassName='rounded-none'
            />
          ) : (
            <GamePackHoverBrandsPreviewMulti
              cards={showcaseCards}
              play
              indicator='inner'
              mediaClassName='rounded-none'
            />
          )}
        </div>
      )}
    </StagedTailwindTransition>
  );
}

function VisibleWatcher(props: { pack: GamePack; onVisible: () => void }) {
  const [horizontalVisible, setHorizontalVisible] = useState(false);
  const [verticalVisible, setVerticalVisible] = useState(false);
  const onVisibleRef = useLatest(props.onVisible);

  useEffect(() => {
    if (horizontalVisible && verticalVisible) {
      onVisibleRef.current();
    }
  }, [horizontalVisible, onVisibleRef, verticalVisible]);

  return (
    <>
      {/* 
        we only care about the first time it's visible.
        remove the waypoint once it is visible.
      */}
      {!verticalVisible && (
        <div className='absolute bottom-0'>
          <Waypoint
            fireOnRapidScroll
            onEnter={() => {
              setVerticalVisible(true);
            }}
          />
        </div>
      )}
      {!horizontalVisible && (
        <div className='absolute right-0'>
          <Waypoint
            fireOnRapidScroll
            horizontal
            onEnter={() => {
              setHorizontalVisible(true);
            }}
          />
        </div>
      )}
    </>
  );
}

export function GamePackCard(props: {
  gamePack: GamePack;
  styles?: GamePackCardStyles;
  badges?: React.ReactNode;
  onClick?: (gamePack: GamePack) => void;
  bottomAccessory?: React.ReactNode;
  showVersion?: boolean;
  lazyLoadCover?: boolean;
  onViewed?: () => void;
}): JSX.Element {
  const durationMin = Math.round(
    props.gamePack.approximateDurationSeconds / 60
  );
  const handleClick = () => {
    props.onClick?.(props.gamePack);
  };
  const gameType = props.gamePack.detailSettings?.gameType;

  const delayedMS = getFeatureQueryParamNumber('game-pack-hover-delayed-ms');

  const [hovered, setHovered] = React.useState(false);
  const [showBrands, setShowBrands] = React.useState(false);

  useEffect(() => {
    if (props.gamePack.version !== EnumsGamePackVersion.GamePackVersionV2)
      return;
    if (!hovered) return;

    const timer = setTimeout(() => {
      setShowBrands(true);
    }, delayedMS);
    return () => {
      clearTimeout(timer);
      setShowBrands(false);
    };
  }, [delayedMS, hovered, props.gamePack.version]);

  return (
    <div
      className={`
        ${
          props.styles?.bg ??
          'bg-lp-gray-009 transition-colors hover:bg-lp-gray-002'
        }
        ${props.styles?.border ?? 'border-none'}
        ${props.styles?.size ?? 'w-62'}
        ${
          props.styles?.cursor ??
          (props.onClick ? 'cursor-pointer' : 'cursor-auto')
        }
        relative rounded-2.5xl group-gamepack-card
      `}
      onClick={handleClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {props.badges && (
        <div
          className={`absolute top-0 left-0 right-0 transform ${
            props.styles?.badgesTranslateClassName ?? '-translate-y-2/4'
          } flex items-center justify-end gap-1 z-10`}
        >
          {props.badges}
        </div>
      )}
      <div className='w-full h-full flex flex-col pt-2 pb-6'>
        <div className='flex-grow flex items-center justify-center px-4.5 py-3.5 font-Montserrat text-sm text-white font-black text-center tracking-wider uppercase truncate'>
          {gameType ? <>{gameType}</> : <>&nbsp;</>}
        </div>
        <div className='flex-grow px-1 relative'>
          <GamePackCoverPres pack={props.gamePack} lazy={props.lazyLoadCover} />

          {showBrands && <GamePackHoverBrandsPreview pack={props.gamePack} />}

          {props.onViewed && (
            <VisibleWatcher pack={props.gamePack} onVisible={props.onViewed} />
          )}
        </div>
        <div className={`flex-grow flex flex-col pt-2.5 px-2.5`}>
          <div className='text-sm font-bold text-center text-white truncate'>
            {props.gamePack.name}
          </div>

          <div className='text-sms text-center text-icon-gray truncate pt-2.5'>
            <ul className='space-x-1'>
              <li className='inline-block'>
                {PlayerRangeUtils.Format(props.gamePack.playerRange)}
              </li>
              <li className='inline-block'>•</li>
              <li className='inline-block'>{`${durationMin} ${pluralize(
                'Min',
                durationMin
              )}`}</li>
              {props.gamePack.detailSettings?.gameDifficulty ===
                EnumsGamePackDifficulty.GamePackDifficultyHard && (
                <>
                  <li className='inline-block'>•</li>
                  <li className='inline-block text-red-006'>Hard</li>
                </>
              )}
              {props.showVersion &&
                props.gamePack.version ===
                  EnumsGamePackVersion.GamePackVersionV2 && (
                  <>
                    <li className='inline-block'>•</li>
                    <li className='inline-block'>
                      <span className='text-tertiary text-sms'>v2</span>
                    </li>
                  </>
                )}
            </ul>
          </div>
        </div>
      </div>
      {props.bottomAccessory}
    </div>
  );
}

function AnonGamePackCardBadges(props: { gamePack: GamePack }) {
  const features = useAnonFeatureChecker();
  return (
    <>
      <NewGamePackBadge gamePack={props.gamePack} />
      <ComingSoonGamePackBadge gamePack={props.gamePack} />
      <FreeGamePackBadge gamePack={props.gamePack} features={features} />
      <ThumbsUpBadge gamePack={props.gamePack} />
    </>
  );
}

function AnonGamePackCardBottomAccessory(props: { pack: GamePack }) {
  return <GamePackPlayable {...props} />;
}

export function CategorizedGamePacks(props: {
  tag: Tag;
  packs: DtoGamePack[];
  showHeader?: boolean;
  viewAll?: (tag: Tag) => string;
  onViewAllClick?: (tag: Tag) => void;
  onClickGamePack?: (pack: GamePack, index: number) => void;
}) {
  const { tag, packs, showHeader = true, viewAll, onClickGamePack } = props;
  const sliderSettings = useGamePackSliderSettings();
  const isHydrated = useHydrated();

  return (
    <div className='w-full flex flex-col text-white'>
      {showHeader && (
        <h3 className={`flex items-center gap-8 group`}>
          {viewAll ? (
            <Link
              to={viewAll(tag)}
              className='btn flex items-center gap-8'
              onClick={() => props.onViewAllClick?.(tag)}
            >
              <p className={`text-2xl font-bold`}>{tag.name}</p>
              <div className='flex items-center gap-2 opacity-0 group-hover:opacity-100'>
                <p>View All</p> <DoubleRightArrow />
              </div>
            </Link>
          ) : (
            <p className={`text-2xl font-bold`}>{tag.name}</p>
          )}
        </h3>
      )}
      {/* 
        Note(guoqiang): the height must be fixed, given that game packs are loaded asynchronously, 
        which ensure the #hash to jump to the right position. Feel free to increase the height
        if you update the game pack card styles.
      */}
      <main
        className={`mt-6 w-full h-76 ${
          !isHydrated ? 'invisible' : 'visible'
        } justify-center items-center`}
      >
        {packs.length === 0 && <ErrorMessage text={`No game packs found`} />}
        {packs.length > 0 && (
          <Slider {...sliderSettings} className='w-full'>
            {packs?.map((p, index) => (
              <div className='pt-5 px-2' key={p.id}>
                <LinkableAnonGamePackCard
                  pack={fromDTOGamePack(p)}
                  onClick={
                    onClickGamePack
                      ? (pack) => onClickGamePack(pack, index)
                      : undefined
                  }
                />
              </div>
            ))}
          </Slider>
        )}
      </main>
    </div>
  );
}

export function AnonGamePackCard(props: {
  pack: GamePack;
  onClick?: (gamePack: GamePack) => void;
}) {
  return (
    <GamePackCard
      gamePack={props.pack}
      onClick={props.onClick}
      badges={<AnonGamePackCardBadges gamePack={props.pack} />}
      bottomAccessory={<AnonGamePackCardBottomAccessory pack={props.pack} />}
      styles={{
        size: 'w-full',
      }}
      lazyLoadCover
    />
  );
}

export function LinkableAnonGamePackCard(props: {
  pack: GamePack;
  onClick?: (gamePack: GamePack) => void;
}) {
  return (
    <Link to={makeAnonPackUrl(props.pack)} onClick={(e) => e.preventDefault()}>
      <AnonGamePackCard {...props} />
    </Link>
  );
}

const getNumPerRow = (w: number): number => {
  for (let index = settings.responsive.length - 1; index >= 0; index--) {
    const e = settings.responsive[index];
    if (w <= e.breakpoint) {
      return e.num;
    }
  }
  return settings.num;
};

const useWindowSize = (initialWidth = Infinity) => {
  const [width, setWidth] = useState(initialWidth);

  useSSRLayoutEffect(() => {
    const handler = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handler);
    handler();
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return width;
};

export function useHydratedNumPerRow(): number {
  const width = useWindowSize();
  const isHydrated = useHydrated();
  return useMemo(
    () => (isHydrated ? getNumPerRow(width) : 0),
    [isHydrated, width]
  );
}
