const TimerIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.31131 2.18869C6.31131 1.80832 6.61963 1.5 7 1.5C10.0329 1.5 12.5 3.967 12.5 7C12.5 10.033 10.033 12.5 7 12.5C3.967 12.5 1.5 10.033 1.5 7C1.5 5.24946 2.34468 3.58858 3.7598 2.55538C4.06701 2.33106 4.49786 2.39837 4.72212 2.70549C4.94644 3.0127 4.87914 3.44357 4.57201 3.66783C3.51101 4.4425 2.87739 5.68868 2.87739 7C2.87739 9.27291 4.7271 11.1226 7 11.1226C9.27291 11.1226 11.1226 9.27291 11.1226 7C11.1226 4.96189 9.63499 3.2637 7.6887 2.93495V4.19408C7.6887 4.57445 7.38038 4.88277 7 4.88277C6.61963 4.88277 6.31131 4.57445 6.31131 4.19408V2.18869ZM4.99721 4.96674C4.72532 4.78787 4.35991 4.86327 4.18103 5.13516C4.00216 5.40705 4.07756 5.77246 4.34945 5.95134L6.64685 7.46278C6.91874 7.64166 7.28416 7.56625 7.46303 7.29436C7.6419 7.02247 7.5665 6.65706 7.29461 6.47818L4.99721 4.96674Z'
      />
    </svg>
  );
};

export { TimerIcon };
