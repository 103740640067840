export const ThumbsUpIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className ?? 'w-[11px] h-[13px] fill-[#FBB707]'}
      viewBox='0 0 11 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.3869e-05 11.3154V5.38765H1.44623C1.68462 5.38765 1.84354 5.2764 2.01835 5.10159L4.79948 1.8278C4.9743 1.65298 5.06965 1.4146 5.06965 1.16033V0.349825C5.06965 0.0955504 5.30803 0.000197312 5.54641 0.000197312C6.19799 -0.0156949 8.0097 0.921943 5.94372 5.16516L9.66249 5.18105C10.2187 5.18105 10.6637 5.62603 10.6637 6.18225C10.6637 6.73848 10.2187 7.18346 9.66249 7.18346H9.39232C9.94855 7.18346 10.3935 7.62844 10.3935 8.18467C10.3935 8.7409 9.94855 9.18588 9.39232 9.18588H9.20162C9.75784 9.18588 10.2028 9.63086 10.2028 10.1871C10.2028 10.7433 9.75784 11.1883 9.20162 11.1883H9.26518C9.71017 11.1883 10.0757 11.5538 10.0757 11.9988C10.0757 12.555 9.6307 13 9.07448 13H8.29576C6.34102 13 4.38629 12.698 2.51101 12.1259L4.3869e-05 11.3154Z'
        // fill='currentColor'
      />
    </svg>
  );
};
