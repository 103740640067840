import '../../../src/assets/scss/slick-theme.scss';
import '../../../src/assets/scss/slick.scss';

import Slider from 'react-slick';

import { isServer } from '../../../src/utils/isServer';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Component = isServer() ? Slider.default : Slider;

export { Component as Slider };
