export const ErrorMessage = (props: {
  text: string;
  handleRetry?: () => void;
}): JSX.Element => {
  let retry = null;
  if (props.handleRetry) {
    retry = (
      <>
        , Please{' '}
        <button
          type='button'
          className='text-primary outline-none focus:outline-none'
          onClick={props.handleRetry}
        >
          try
        </button>{' '}
        again.
      </>
    );
  }
  return (
    <p className='text-secondary'>
      {props.text}
      {retry}
    </p>
  );
};
